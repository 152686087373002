var throttleTimer;
const throttle = (callback, time) => {
  if (throttleTimer) return;

  throttleTimer = true;

  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

const handleInfiniteScroll = () => {
    throttle(() => {
        const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 1000;
        if (endOfPage) {
            var articles = document.getElementsByClassName("feed-article d-none");
            
            if (articles?.length < 1) {
                window.removeEventListener("scroll", handleInfiniteScroll);
                return;
            }
            
            var amount = articles.length > 4 ? 4 : articles.length;
            for(var i = 0; i < amount; i++) 
            {
                articles[0].classList.remove("d-none");
            }
        }
    }, 200);
  };

var infinite = document.getElementsByClassName("infinite");

if (infinite?.length > 0) 
{   
    window.addEventListener("scroll", handleInfiniteScroll);
}